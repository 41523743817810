import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { IOttoMotorsSeo } from "@ottomotors/ottomotors-sanity";
import { useSiteMetadata } from "@ottomotors/ottomotors.com/hooks";
import FacebookSEO from "./Facebook";
import TwitterSEO from "./Twitter";

interface IProps {
  pageTitle: string;
  description?: string;
  openGraphImageUrl?: string;
  keywords?: string[];
  pathname?: string;
  pageType?: string;
  noIndex?: boolean;
}

const RESOURCE_TYPE_PARAMS = ["blogArticle", "webinar", "caseStudy"];

const SEO = ({
  pageTitle,
  pathname,
  description: pageDescription,
  openGraphImageUrl,
  pageType,
  noIndex,
}: IProps) => {
  const globalSEO: IOttoMotorsSeo = useStaticQuery(graphql`
    query {
      sanityOttomotorsSettings {
        seo {
          title
          keywords
          # tagline
          description
          image {
            asset {
              url
            }
          }
          # favicon {
          #   asset {
          #     url
          #   }
          # }
        }
      }
    }
  `)?.sanityOttomotorsSettings?.seo;

  const meta = useSiteMetadata();

  if (!globalSEO) {
    return null;
  }

  const {
    description: globalDescription,
    // favicon,
    image,
    keywords,
    // tagline,
    title: globalTitle,
  } = globalSEO;

  const titleText =
    pageTitle === `Home` ? `${globalTitle}` : `${pageTitle} | ${globalTitle}`;
  const descriptionText = pageDescription || globalDescription;
  const ogImageUrl = openGraphImageUrl || image.asset.url!;
  // const faviconUrl = favicon?.asset?.url!;
  const keywordsText = keywords ? keywords.join(", ") : null;

  const getTypeFromURL = () => {
    if (typeof window === "undefined") return "";

    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("type") || "";
  };

  let queryString = "";
  let type = pageType || "";
  let subpath = "";

  switch (type) {
    case "ottomotors.pressRelease":
      subpath = "/company/newsroom/press-releases";
      break;
    case "ottomotors.caseStudy":
      subpath = "/resources/case-studies";
      break;
    case "ottomotors.blogArticle":
      subpath = "/blog";
      break;
    case "ottomotors.ebook":
      subpath = "/resources/ebooks";
      break;
    case "ottomotors.webinar":
      subpath = "/resources/webinars";
      break;
    case "ottomotors.video":
      subpath = "/resources/videos";
      break;
    case "ottomotors.whitePaper":
      subpath = "/resources/white-papers";
      break;
    case "ottomotors.download":
      subpath = "/resources/downloads";
      break;
    case "ottomotors.resourcePage":
      subpath = "/resources";
      break;
    case "ottomotors.event":
      subpath = "/events";
      break;
    default:
      break;
  }

  // only add query strings on the resources page
  if (pathname === "/resources/") {
    const typeParam = getTypeFromURL();

    if (RESOURCE_TYPE_PARAMS.includes(typeParam)) {
      queryString = `?type=${typeParam}`;
    }
  }

  const url = `${meta.siteUrl}${subpath}${pathname}${queryString}`;

  return (
    <>
      <title>{titleText}</title>
      {/* <link rel="icon" type="image/png" href={faviconUrl} /> */}
      <link rel="canonical" href={url} />
      <meta name="description" content={descriptionText} />
      {noIndex === true && <meta name="robots" content="noindex" />}

      {keywordsText && <meta name="keywords" content={keywordsText} />}

      <FacebookSEO
        description={descriptionText}
        ogImageUrl={ogImageUrl}
        siteUrl={url}
        title={titleText}
      />
      <TwitterSEO
        description={descriptionText}
        ogImageUrl={ogImageUrl}
        title={titleText}
      />
    </>
  );
};

export default SEO;
